import { Select } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import CircularLoader from "../loader/circulat-loader";
import { ArrowDownIcon, CheckmarkIcon, CloseIcon } from "../svg/icons";
import "./_style.scss";
import { DescriptiveDropdownProps, DropdownMode } from "./entity";

const { Option } = Select;

const DescriptiveDropdown = <T,>(props: DescriptiveDropdownProps<T>) => {
  const {
    mode = DropdownMode.Default,
    placeholder,
    defaultValue,
    onSearch,
    onBlur,
    onFocus,
    isLoading = false,
    showSearch = true,
    allowClear = false,
    maxSelection = false,
    selectedValues,
    onChange,
    descriptionJSX,
    footerJSX,
    options,
    disabled,
    isError,
    isMethodSelection,
    extraNoteTitle,
    className,
    onPopupScroll,
    notFoundContent,
    popupMatchSelectWidth = true,
    listHeight,
  } = props;

  const selectClassname = classNames("mw-dropdown-element", className, {
    "is-error": isError,
    "is-disabled": disabled,
    "is-method-selection": isMethodSelection,
    "is-last-option-note": extraNoteTitle && extraNoteTitle !== "",
  });

  const [highlightedOption, setHighlightedOption] = useState<string | number>(
    Array.isArray(selectedValues)
      ? selectedValues[0]
      : selectedValues ?? options[0]?.value
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const key = event.key;
    const currentIndex = options.findIndex(
      (opt) => opt.value === (highlightedOption ?? options[0].value)
    );

    if (key === "ArrowDown") {
      setHighlightedOption(
        currentIndex < options.length - 1
          ? options[currentIndex + 1].value
          : options[0].value
      );
    } else if (key === "ArrowUp") {
      setHighlightedOption(
        currentIndex > 0
          ? options[currentIndex - 1].value
          : options[options.length - 1].value
      );
    }
  };

  const dropdownRender = (menu: React.ReactNode) => {
    const descriptionDetails =
      options.find((opt) => opt.value === highlightedOption)
        ?.descriptionDetails ?? options[0]?.descriptionDetails;
    return (
      <div className="dropdown-content-container">
        <div className="dropdown-content">
          <div className="dropdown-options">{menu}</div>
          <div className="dropdown-description">
            {descriptionJSX(descriptionDetails)}
          </div>
        </div>
        {footerJSX && <div className="dropdown-footer">{footerJSX?.()}</div>}
      </div>
    );
  };

  const handleSelect = (value: string[]) => {
    const newValue = [...value].splice(-maxSelection);
    onChange(maxSelection ? newValue : value);

    return value;
  };

  const finalOptions = [...options];
  extraNoteTitle && extraNoteTitle !== ""
    ? finalOptions.push({
        label: extraNoteTitle,
        value: extraNoteTitle,
        disabled: mode !== DropdownMode.AddOptions && true,
        className: "is-last-option-note",
      })
    : options;

  return (
    <div className="mw-dropdown-selection">
      <Select
        mode={mode}
        value={selectedValues}
        onChange={handleSelect}
        onSearch={onSearch}
        onBlur={onBlur}
        onFocus={onFocus}
        allowClear={allowClear}
        disabled={disabled}
        placeholder={placeholder ?? "Select value"}
        notFoundContent={notFoundContent ?? "No record found"}
        defaultValue={defaultValue}
        dropdownRender={dropdownRender}
        showSearch={showSearch}
        suffixIcon={isLoading ? <CircularLoader /> : <ArrowDownIcon />}
        removeIcon={<CloseIcon color="var(--color-primary)" />}
        onInputKeyDown={handleKeyDown}
        rootClassName={selectClassname}
        onPopupScroll={onPopupScroll}
        menuItemSelectedIcon={<CheckmarkIcon color="var(--color-primary)" />}
        onSelect={(value: string) => setHighlightedOption(value)}
        popupMatchSelectWidth={popupMatchSelectWidth}
        listHeight={listHeight}
      >
        {finalOptions.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            className={option.className}
            onMouseEnter={() => setHighlightedOption(option.value)}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default DescriptiveDropdown;
