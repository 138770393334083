import { Popover } from "core/components/v2/popover";
import React, { useCallback, useEffect, useState } from "react";
import { uniqWith, isEqual } from "lodash-es";
import {
  GlobalSearchProps,
  MetricsType,
  OptionsType,
  SelectionType,
} from "./model";
import PopoverContent from "./popoverContent/PopoverContent";
import "./_style.scss";
import {
  AIFilterTagIcon,
  AISearchIcon,
  CancelIcon,
  SearchIcon,
} from "core/components/v2/svg/icons";
import Button from "core/components/v2/button";
import MwRouteContext from "../../routes/MWRouteContext";
import { QueryParams } from "../../routes/model";
import Tooltip from "core/components/v2/tooltip";
import { debounceHandler } from "core/utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchMetricList, requestBuilderData } from "store/widgets/api";
import {
  BarType,
  DataType,
  FiltersAPIResp,
  MetricsAPIResp,
} from "store/widgets/entities";
import { receivedBarFilters } from "store/widgets/actions";
import {
  CustomWidget,
  FilterDataType,
  FilterOpEnum,
  GLOBAL_ATTRIBUTE_FILTER,
  GridviewData,
  Query,
  RequestParam,
  SELECT_DATA_BY,
  SqlOperatorType,
  builderFiltersInstance,
  defaultQuery,
  operatorMapping,
  sqlOperators,
  toKpiType,
} from "views/modules/builder/entities/builder.entities";
import { getBarFilters } from "store/widgets/selectors";
import AppContext from "core/components/wrapper/context";

const filterValuesMap: Record<string, Record<string, string>> = {
  kind: {
    "0": "Unspecified",
    "1": "Internal",
    "2": "Server",
    "3": "Client",
    "4": "Producer",
    "5": "Consumer",
    key: "Span Kind",
  },
  statusCode: {
    "0": "Unset",
    "1": "Ok",
    "2": "Error",
    key: "Span Status",
  },
};

const GlobalQueryBar = (props: GlobalSearchProps) => {
  const {
    caller,
    placeholder,
    extraBtnPrefixIcon,
    extraBtnLabel,
    onExtraBtnClick,
    includeMetricTypes = [],
    // hideGroupByLabel = false,
    extraJSXElement,
    allowConditionalOperators = false,
    extraAttributes = [],
    customFilters = {},
    isCustom = false,
    onChange,
    uniqueId,
    clearTableData,
    handleRunQuery,
    regexSearch,
    extraCaller,
  } = props;

  const extraOperators: string[] = allowConditionalOperators
    ? ["<=", ">=", "<", ">"]
    : [];
  const ALLOWED_OPERATORS: string[] = [
    ...extraOperators,
    "!=",
    "NOT IN",
    "NOT LIKE",
    "=",
    "IN",
    "LIKE",
    "REGEX",
    "NOT REGEX",
  ];

  const routeData = React.useContext(MwRouteContext);
  const context = React.useContext(AppContext);
  const dispatch = useDispatch();
  const _filters = routeData.params.appliedFilters;
  const querybarFilters = useSelector(getBarFilters(BarType.Querybar));

  const [popoverContentVisible, setPopoverContentVisible] = useState(false);
  const [input, setInput] = useState("");
  const [tempVal, setTempVal] = useState<string>("");
  const [aiSearchInput, setAiSearchInput] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");
  const [editQuery, setEditQuery] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState<QueryParams>({});
  const [currentType, setCurrentType] = useState<SelectionType>(
    SelectionType.Attributes
  );
  const [metricOptions, setMetricOptions] = useState<MetricsType>({});
  const [highlitedIndex, setHighlitedIndex] = useState<number>(-1);
  const [fixedOptions, setFixedOptions] = useState<OptionsType[]>([]);
  const [options, setOptions] = useState<OptionsType[]>([]);
  const [runningQuery, setRunningQuery] = useState<{
    attribute?: string;
    operator?: FilterOpEnum;
    values?: string[];
  }>({
    attribute: "",
    operator: FilterOpEnum.Equal,
    values: [],
  });
  const [inFilter, setInFilter] = useState({});

  const regexSearchOption = regexSearch || "body";
  const startDateParam = routeData.params.dateRange.fromTs;
  const endDateParam = routeData.params.dateRange.toTs;
  let is_datadog_agent = false;
  let datdog_agent_installed = false;
  if (context.user?.misc_details?.datadog_installed === true) {
    datdog_agent_installed = true;
  }
  if (
    context.user?.misc_details?.datadog_installed === true &&
    context.user?.settings?.live_infra_dashboard?.value !== "mw_agent"
  ) {
    is_datadog_agent = true;
  }

  useEffect(() => {
    if (!isCustom) {
      setAppliedFilters(_filters);
    }
  }, [JSON.stringify(_filters)]);

  useEffect(() => {
    if (isCustom) {
      setAppliedFilters(customFilters);
    }
  }, [JSON.stringify(customFilters)]);

  useEffect(() => {
    const searchAttr = getAttrNameFromSearch(input);
    if (currentType !== SelectionType.Attributes) {
      return;
    }
    onSearch(searchAttr);
    input.length === 1 && setAiSearchInput("");
  }, [input]);

  useEffect(() => {
    if (!popoverContentVisible || currentType !== SelectionType.Attributes)
      return;
    fetchFilterAttributes(getAttrNameFromSearch(input));
  }, [popoverContentVisible]);

  useEffect(() => {
    if (
      runningQuery.attribute !== "" &&
      runningQuery.operator &&
      !popoverContentVisible
    )
      setPopoverContentVisible(true);
  }, [runningQuery.operator]);

  const getLogOptions = (): MetricsType => {
    const logOptions: MetricsType = {};
    if (!querybarFilters.filters.items?.length) return logOptions;

    querybarFilters.filters.items.forEach((item) => {
      if (!item.name) return;
      logOptions[item.name] = item;
    });

    return logOptions;
  };

  useEffect(() => {
    const logOptions: MetricsType = getLogOptions() || {};
    setMetricOptions(logOptions);

    let opts: OptionsType[] = [];

    const extraOpts = extraAttributes
      .filter((opt) => typeof opt.options !== "undefined")
      .map((opt) => opt.value);

    if (currentType === SelectionType.Attributes) {
      opts = Object.keys(logOptions).map((key: string) => {
        const label = logOptions[key]?.label;
        return {
          value: key,
          label: label && label.length > 0 ? label : key,
        };
      });
      if (extraAttributes?.length) {
        opts = [...extraAttributes, ...opts];
      }
    } else if (currentType === SelectionType.Operator) {
      opts = sqlOperators
        .filter(
          (item: SqlOperatorType) => ALLOWED_OPERATORS.indexOf(item.value) > -1
        )
        .map((f: SqlOperatorType) => {
          return {
            value: f.value,
            label: f.title,
          };
        });
      if (extraOpts.includes(runningQuery.attribute ?? "")) {
        opts = sqlOperators
          .filter((operator) => operator.value === "=")
          .map((f: SqlOperatorType) => {
            return {
              value: f.value,
              label: f.title,
            };
          });
      }
    } else {
      if (extraOpts.includes(runningQuery.attribute ?? "")) {
        const options = extraAttributes
          .filter((opt) => opt.value === runningQuery.attribute)
          .map((opt) => opt.options)[0];
        options?.forEach((item) => {
          opts.push({
            value: item,
            label: item,
          });
        });
      } else if (runningQuery?.attribute) {
        const customWidget: CustomWidget[] = [];
        const _params: RequestParam[] = [
          { key: "from_ts", value: startDateParam },
          { key: "to_ts", value: endDateParam },
        ];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
        const resourceType = {
          columns: [runningQuery.attribute || ""],
          resourceType: caller,
          name: `get-filter-data`,
          widgetAppId: 5,
        };
        userQuery.columns = resourceType.columns;
        userQuery.source.name = resourceType.resourceType;
        userQuery.with = [];
        const inFilters: FilterDataType[] = [];
        const likeFilters: FilterDataType[] = [];
        const inFiltersAnd = builderFiltersInstance.And(inFilters);
        const likeFiltersAnd = builderFiltersInstance.Or(likeFilters);
        let filters = undefined;
        if (inFiltersAnd && likeFiltersAnd) {
          filters = builderFiltersInstance.And([inFiltersAnd, likeFiltersAnd]);
        } else if (inFiltersAnd) {
          filters = inFiltersAnd;
        } else if (likeFiltersAnd) {
          filters = likeFiltersAnd;
        }
        if (filters) {
          const fValues = builderFiltersInstance.ConvertToValueType(filters);
          if (fValues) {
            userQuery.with.push({
              key: GLOBAL_ATTRIBUTE_FILTER,
              value: fValues,
              is_arg: true,
            });
          }
        }
        userQuery.with.push({
          key: SELECT_DATA_BY,
          value: resourceType.columns,
          is_arg: true,
        });
        let mw_filter = null;
        if (is_datadog_agent) {
          mw_filter = builderFiltersInstance.Equal("mw_source", "datadog");
        } else {
          if (datdog_agent_installed) {
            mw_filter = builderFiltersInstance.NotEqual("mw_source", "datadog");
          }
        }
        if (mw_filter) {
          userQuery.with.push({
            key: GLOBAL_ATTRIBUTE_FILTER,
            value: mw_filter,
            is_arg: true,
          });
        }
        const widget: CustomWidget = {
          label: "get-filter-values",
          builderId: 0,
          scopeId: 0,
          widgetAppId: 5,
          builderConfig: userQuery,
          builderViewOptions: {
            resource: resourceType,
          },
          params: _params,
        };
        customWidget.push(widget);
        if (extraCaller && extraCaller?.length > 0) {
          extraCaller.forEach(addCaller => {
            const addQuery = {
              ...userQuery,
              source: {
                ...userQuery.source,
                name: addCaller,
              }
            }
            const addWidget = { ...widget }
            addWidget.builderConfig = addQuery
            customWidget.push(addWidget)
          })
        }
        opts =
          logOptions[runningQuery.attribute]?.values?.map((item: string) => {
            return {
              value: item,
              label: item,
            };
          }) || [];
        dispatch(
          requestBuilderData(
            customWidget,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (status: boolean, inflight: boolean, data?: any) => {
              if (status && data && data.length > 0) {
                let newopts: OptionsType[] = [];
                for (let ind = 0; ind < data.length; ind++) {
                  const index = ind;
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  const items = data[index].chart_data.data as GridviewData;
                  items.forEach((d: Record<string, any>) => {
                    const attribute = resourceType.columns[0];
                    const attributeValue =
                      typeof d[resourceType.columns[0]] === "boolean"
                        ? d[resourceType.columns[0]].toString()
                        : d[resourceType.columns[0]];
                    //TODO: Need to add relevant props instead of static array of filter keys & filterValue map
                    if (["kind", "statusCode"].includes(attribute)) {
                      newopts.push({
                        value: d[attribute].toString(),
                        label: filterValuesMap[attribute][d[attribute]],
                      });
                    } else if (attributeValue.length > 0) {
                      newopts.push({
                        value: attributeValue,
                        label: attributeValue,
                      });
                    }
                  });
                }
                if (extraCaller && extraCaller?.length > 0) {
                  newopts = uniqWith(newopts, isEqual)
                }
                setFixedOptions([...newopts]);
                setOptions([...newopts]);
              }
            }
          )
        );
      } else {
        opts = [];
      }
    }

    setFixedOptions([...opts]);
    setOptions([...opts]);
  }, [querybarFilters, currentType]);

  useEffect(() => {
    if (aiSearchInput.trim().length && input.length) {
      setInput("");
      setCurrentType(SelectionType.Attributes);
      setRunningQuery({
        attribute: "",
        operator: FilterOpEnum.Equal,
        values: [],
      });
    }
    if (Object.keys(appliedFilters).length) {
      if (!isCustom) {
        routeData.actions.handleApplyFilters({});
      }
      if (onChange) onChange({});
    }
  }, [aiSearchInput]);

  const fetchFilterAttributes = (search: string) => {
    dispatch(
      fetchMetricList(
        {
          kpiType: toKpiType(caller),
          widgetType: "list",
          dataType: DataType.Filters,
          resource: caller,
          filterTypes: JSON.stringify(includeMetricTypes),
          search,
        },
        (resp: MetricsAPIResp) =>
          receivedBarFilters(BarType.Querybar, resp as FiltersAPIResp)
      )
    );
  };

  const selectOptionHandler = (
    option: string,
    append = false,
    isMultiValue = false
  ) => {
    if (append) {
      if (currentType == SelectionType.Attributes) {
        setInput(`${option}`.trim());
      } else if (isMultiValue) {
        setInput(
          (runningQuery.values ?? []).includes(option)
            ? input
              .replace(new RegExp(`\\b${option}\\b,?\\s*`), "")
              .replace(/,\s*$/, "")
              .trim()
            : `${input}, ${option}`.trim()
        );
      } else {
        setInput(`${input} ${option}`.trim());
      }
    } else {
      setInput(input + " ");
    }

    runningQuery.operator !== FilterOpEnum.In &&
      runningQuery.operator !== FilterOpEnum.NotIn &&
      setHighlitedIndex(-1);

    switch (currentType) {
      case SelectionType.Attributes: {
        setCurrentType(SelectionType.Operator);
        aiSearchInput && setAiSearchInput("");
        setRunningQuery({
          ...runningQuery,
          attribute: option,
        });
        break;
      }

      case SelectionType.Operator: {
        setRunningQuery({
          ...runningQuery,
          operator: operatorMapping[option],
        });
        setCurrentType(SelectionType.Value);
        break;
      }

      case SelectionType.Value: {
        if (
          runningQuery.attribute &&
          runningQuery.operator &&
          [FilterOpEnum.In, FilterOpEnum.NotIn].includes(runningQuery.operator)
        ) {
          const fltr: Record<
            string,
            {
              operator: FilterOpEnum;
              values: string[];
            }
          > = {
            [runningQuery.attribute]: {
              operator: runningQuery.operator,
              values: runningQuery.values?.includes(option)
                ? runningQuery.values.filter((val) => val !== option)
                : [...(runningQuery.values ?? []), option],
            },
          };
          setInFilter(fltr);
          setRunningQuery({
            attribute: runningQuery.attribute,
            operator: runningQuery.operator,
            values: runningQuery.values?.includes(option)
              ? runningQuery.values.filter((val) => val !== option)
              : [...(runningQuery.values ?? []), option],
          });
        } else if (runningQuery.attribute && runningQuery.operator) {
          const fltr: Record<
            string,
            {
              operator: FilterOpEnum;
              values: string[];
            }
          > = {
            [runningQuery.attribute]: {
              operator: runningQuery.operator,
              values: [option],
            },
          };
          handleApplyFilter(fltr);
        }
        break;
      }

      default:
        break;
    }
  };

  const handleApplyFilter = (fltr: QueryParams) => {
    setCurrentType(SelectionType.Attributes);
    if (!isCustom) {
      routeData.actions.handleApplyFilters(fltr);
    }
    if (onChange) onChange(fltr);
    setInput("");
    setPopoverContentVisible(false);
    setRunningQuery({
      attribute: "",
      operator: FilterOpEnum.Equal,
      values: [],
    });
  };

  // useCallback is used to use the same function reference between re-renders.
  // It will help to prevent calling the debounceHandler multiple times due to
  // new function definition creation.
  const onSearch = useCallback(
    debounceHandler(-1, fetchFilterAttributes, 500),
    [caller, JSON.stringify(includeMetricTypes)]
  );

  const getAttrNameFromSearch = (search: string) => {
    if (currentType === SelectionType.Attributes) {
      return search;
    } else if (currentType === SelectionType.Operator) {
      const splits = search.split(`${runningQuery.attribute}`);
      if (splits.length > 1) {
        return splits[1].trim();
      }
    } else {
      const splits = search.split(
        `${runningQuery.attribute + " " + runningQuery.operator}`
      );
      if (splits.length > 1) {
        return splits[1].trim();
      }
    }

    return "";
  };

  const handleClosePopover = () => {
    setPopoverContentVisible(false);
    setEditQuery(false)
  };

  const handleAiSearchInput = (text: string) => {
    setAiSearchInput(text);
  };

  const handleSearchedQuery = (text: string) => {
    setSearchedQuery(text);
  };

  const handleEditFilter = (filter: string) => {
    setRunningQuery({
      attribute: filter,
      operator: appliedFilters[filter].operator,
    });
    setInput(`${filter} ${appliedFilters[filter].operator}`);
    setCurrentType(SelectionType.Value);
  };

  const handleRemoveFilters = (filterKey: string) => {
    const tempArr = { ...appliedFilters };
    delete tempArr[filterKey];
    appliedFilters[filterKey].values = [];
    if (!isCustom) {
      routeData.actions.handleApplyFilters(appliedFilters);
    }
    if (onChange) onChange(appliedFilters);
  };

  const setOptionValues = (text?: string) => {
    if (text) {
      const opts = fixedOptions.filter((item: OptionsType) => {
        return item.label.includes(text);
      });
      setOptions(opts);
    } else {
      setOptions(fixedOptions);
    }
  };

  const onPrimaryInputChangeHandler = (text: string) => {
    if (text.trim() === "") setCurrentType(SelectionType.Attributes);

    setInput(text);
    setTempVal(text);
    setHighlitedIndex(-1);
    setOptionValues(getAttrNameFromSearch(text));
  };

  const resetOptions = () => {
    let opts: OptionsType[] = [];

    opts =
      Object.keys(metricOptions).map((key: string) => {
        return {
          value: key,
          label: key,
        };
      }) || [];

    setOptions(opts);
    setFixedOptions(opts);
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Escape") {
      setInput("");
      setPopoverContentVisible(false);
      setRunningQuery({
        attribute: "",
        operator: FilterOpEnum.Equal,
        values: [],
      });
      setCurrentType(SelectionType.Attributes);
      resetOptions();
    }

    if (event.key === "ArrowUp") {
      if (highlitedIndex == 0) {
        setInput(tempVal);
      }
      if (highlitedIndex >= 0) {
        if (highlitedIndex > 0) {
          if (currentType === SelectionType.Attributes) {
            setInput(options[highlitedIndex - 1]?.value);
          } else if (currentType === SelectionType.Operator) {
            setInput(
              runningQuery.attribute + " " + options[highlitedIndex - 1].value
            );
          } else if (
            runningQuery.operator === FilterOpEnum.In ||
            runningQuery.operator === FilterOpEnum.NotIn
          ) {
            setInput(
              runningQuery.attribute +
              " " +
              runningQuery.operator +
              " " +
              [
                ...(runningQuery.values ?? []),
                !runningQuery.values?.includes(
                  options[highlitedIndex + 1]?.value
                )
                  ? options[highlitedIndex + 1]?.value
                  : "",
              ].join(", ")
            );
          } else {
            setInput(
              runningQuery.attribute +
              " " +
              runningQuery.operator +
              " " +
              options[highlitedIndex - 1].value
            );
          }
        }

        setHighlitedIndex(highlitedIndex - 1);
      }
    }
    if (event.key === "ArrowDown") {
      if (highlitedIndex === -1) {
        setTempVal(input);
      }
      if (options.length - 1 > highlitedIndex) {
        if (currentType === SelectionType.Attributes) {
          setInput(options[highlitedIndex + 1].value);
        } else if (currentType === SelectionType.Operator) {
          setInput(
            runningQuery.attribute + " " + options[highlitedIndex + 1].value
          );
        } else if (
          runningQuery.operator === FilterOpEnum.In ||
          runningQuery.operator === FilterOpEnum.NotIn
        ) {
          setInput(
            runningQuery.attribute +
            " " +
            runningQuery.operator +
            " " +
            [
              ...(runningQuery.values ?? []),
              !runningQuery.values?.includes(
                options[highlitedIndex + 1].value
              )
                ? options[highlitedIndex + 1].value
                : "",
            ].join(", ")
          );
        } else {
          setInput(
            runningQuery.attribute +
            " " +
            runningQuery.operator +
            " " +
            options[highlitedIndex + 1].value
          );
        }

        setHighlitedIndex(highlitedIndex + 1);
      }
    }

    if (event.key === "Enter") {
      let fltr: Record<
        string,
        {
          operator: FilterOpEnum;
          values: string[];
        }
      > = {};
      if (highlitedIndex > -1) {
        selectOptionHandler(options[highlitedIndex]?.value);
      } else {
        if (currentType === SelectionType.Attributes) {
          if (input.startsWith("!") || input.startsWith("~")) {
            const str = input.substring(1);
            fltr = {
              [regexSearchOption]: {
                operator: input.startsWith("!")
                  ? FilterOpEnum.NotLike
                  : FilterOpEnum.Regex,
                values: [str],
              },
            };
            if (!isCustom) {
              routeData.actions.handleApplyFilters(fltr);
            }
            if (onChange) onChange(fltr);
            setInput("");
            setOptionValues();
            setPopoverContentVisible(false);
            setCurrentType(SelectionType.Attributes);
            return;
          }
        }

        //1. Checking for splits by checking if the string contains any operator
        //2. If operator exist creating attribute , operator and value
        //3. If not applying whole string to "body" filter

        const splitObj: {
          attribute?: string;
          operator?: FilterOpEnum;
          values?: string[];
        } = {};

        let isQuery = false;

        for (const elem of ALLOWED_OPERATORS) {
          // Operator needs to have a single space around it to be identified as an operator.
          // This is to prevent matching the case where the attribute name or the value itself have the operator.
          // For example: body MUSTBE integer. Here the string has "IN" but it is not the operator.
          const elemRegex = new RegExp(` ${elem} `, "i");

          if (elemRegex.test(input)) {
            const splits = input.split(elemRegex);
            if (splits && splits.length > 1) {
              isQuery = true;
              splitObj.attribute = splits[0].trim();
              splitObj.operator = operatorMapping[elem];
              if (
                operatorMapping[elem] === FilterOpEnum.In ||
                operatorMapping[elem] === FilterOpEnum.NotIn
              ) {
                splitObj.values = (runningQuery.values ?? []).length
                  ? runningQuery.values
                  : splits[1]?.split(",")?.map((s: string) => s.trim()) || [];
              } else {
                splitObj.values = [splits[1].trim()];
              }
            }
            break;
          }
        }

        if (isQuery) {
          if (splitObj?.attribute && splitObj.values && splitObj.operator) {
            fltr = {
              [splitObj.attribute]: {
                operator: splitObj.operator,
                values: splitObj.values,
              },
            };
          }
        } else {
          fltr = {
            [regexSearchOption]: {
              operator: FilterOpEnum.ILIKE,
              values: [input],
            },
          };
        }

        if (!isCustom) {
          routeData.actions.handleApplyFilters(fltr);
        }
        if (onChange) onChange(fltr);
        setInput("");
        setOptionValues();
        setPopoverContentVisible(false);
        setCurrentType(SelectionType.Attributes);
      }
    }
  };

  return (
    <div className="global-search-wrapper">
      <Popover
        isVisible={popoverContentVisible}
        onClose={handleClosePopover}
        content={
          <PopoverContent
            handleRunQuery={handleRunQuery}
            handleAiSearchInput={handleAiSearchInput}
            handleClosePopover={handleClosePopover}
            handleSearchedQuery={handleSearchedQuery}
            editQuery={editQuery}
            aiSearchInput={aiSearchInput}
            currentType={currentType}
            options={options}
            selectOptionHandler={selectOptionHandler}
            runningQuery={runningQuery}
            highlitedIndex={highlitedIndex}
            handleApplyFilter={handleApplyFilter}
            inFilter={inFilter}
          />
        }
        shouldCloseOnInnerClick={false}
        rootClassName="global-search-popover-content"
      >
        <div className="global-search">
          <div className="search-options-container">
            <div className="search-container-outer-div">
              <div className="search-container">
                <div
                  className={`${handleRunQuery ? "ai-search-icon" : "search-icon"} ${!popoverContentVisible ? "show-hovered-background" : ""}`}
                >
                  {handleRunQuery ? <AISearchIcon /> : <SearchIcon />}
                </div>
                <input
                  id={uniqueId}
                  className="input-field"
                  onClick={() => setPopoverContentVisible(true)}
                  value={input}
                  onChange={(e) => onPrimaryInputChangeHandler(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  placeholder={
                    placeholder ?? "Search by name, tag, label or annotation"
                  }
                />
              </div>
            </div>
            {extraBtnLabel && (
              <Button
                secondary
                prefixicon={extraBtnPrefixIcon}
                onClick={onExtraBtnClick}
              >
                {extraBtnLabel}
              </Button>
            )}
            {extraJSXElement}
          </div>
          {aiSearchInput.trim() && (
            <div className="applied-filters-container">
              {(popoverContentVisible || searchedQuery.length > 0) && (
                <span className="applied-filters-label">
                  {popoverContentVisible
                    ? "Searching Result for: "
                    : "Showing Results for:"}
                </span>
              )}
              {(popoverContentVisible || searchedQuery.length > 0) && (
                <div className="filter-tag">
                  <AIFilterTagIcon />
                  <Tooltip content={"Click to edit"}>
                    <div
                      className="filter-info"
                      onClick={() => {
                        setPopoverContentVisible(true);
                        setAiSearchInput(aiSearchInput);
                        setEditQuery(true)
                      }}
                    >
                      <span>
                        {popoverContentVisible ? aiSearchInput : searchedQuery}
                      </span>
                    </div>
                  </Tooltip>
                  <div
                    className="icon-container"
                    onClick={() => {
                      setAiSearchInput("");
                      clearTableData?.();
                    }}
                  >
                    <CancelIcon size={10} />
                  </div>
                </div>
              )}
            </div>
          )}
          {appliedFilters && Object.keys(appliedFilters).length > 0 && (
            <div className="applied-filters-container">
              <span className="applied-filters-label">Applied Filters:</span>
              {/* //TODO: Need to add relevant props instead of static array of filter keys & filterValue map */}
              {Object.keys(appliedFilters).map((filter) => {
                const filterValues = ["kind", "statusCode"].includes(filter)
                  ? appliedFilters[filter].values
                    .map((val) => filterValuesMap[filter][val])
                    .join(", ")
                  : appliedFilters[filter].values.join(",");
                return (
                  <div key={filter} className="filter-tag">
                    <Tooltip content={"Click to edit"}>
                      <div
                        className="filter-info"
                        onClick={() => handleEditFilter(filter)}
                      >
                        <span>
                          {["kind", "statusCode"].includes(filter)
                            ? filterValuesMap[filter].key
                            : filter}
                        </span>
                        <span>{appliedFilters[filter].operator}</span>
                        <span className="filter-value-span">
                          {filterValues}
                        </span>
                      </div>
                    </Tooltip>
                    <div
                      onClick={() => handleRemoveFilters(filter)}
                      className="icon-container"
                    >
                      <CancelIcon size={10} />
                    </div>
                  </div>
                );
              })}
              <span
                className="clear-all"
                onClick={() => {
                  if (!isCustom) {
                    routeData.actions.handleApplyFilters({});
                  }
                  if (onChange) onChange({});
                }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default GlobalQueryBar;
