import {
  DecodeShortUrlToString,
  EncodeStringToShortUrl,
  getQueryParams,
} from "core/application/utils";
import { TimeRangeOption } from "core/components/datepicker/model";
import usePrevious from "core/components/previous-hook";
import useQuery from "core/components/query";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getMinutesDifference,
  getRoundedInterval,
} from "views/modules/apm/utils";
import { getTimeFromRange } from "../components/global-date-picker/model";
import {
  AllRouteParams,
  DateRange,
  GlobalRouteParams,
  MwQuery,
  MwRouteData,
  QueryParams,
} from "./model";
import { GetRounderdOneHourTs, getTimeRangeFromLocation } from "./utils";
const MaxMinutesToChange = 1;
const useMwRouteData = (userName: string) => {
  // coreQuery will be always updated with latest query params
  const coreQuery = useQuery();
  const mwQuery: MwQuery = useRef({
    set: (key: string, value: string) => {
      coreQuery.set(key, value);
    },
    get: (key: string) => {
      return coreQuery.get(key) || undefined;
    },
    has: (key: string) => {
      return coreQuery.has(key);
    },
    delete: (key: string) => {
      coreQuery.delete(key);
    },
    toString: () => {
      return coreQuery.toString();
    },
    redirect: () => {
      redirect();
    },
  }).current;
  const debug = mwQuery.has(GlobalRouteParams.Debug);
  const isLive = mwQuery.get(GlobalRouteParams.Live) !== "false";

  useEffect(() => {
    if (!mwQuery.has(GlobalRouteParams.Live) || isLive) {
      mwQuery.set(GlobalRouteParams.Live, "true");
      redirect();
    }
  }, []);

  const PrintLog = (msg: string) => {
    // if (debug) {
    //   console.debug(`[useMwRouteData][${userName}] ${msg}`);
    // }
  };

  const currentLocation = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const state = currentLocation.state;
  const previousLocation = usePrevious(currentLocation);
  const navigate = useNavigate();
  const initialFromTs = mwQuery.get(GlobalRouteParams.FromTs);
  const initialToTs = mwQuery.get(GlobalRouteParams.ToTs);
  const fromTs = initialFromTs
    ? parseInt(initialFromTs)
    : GetRounderdOneHourTs(new Date().valueOf() - 3600 * 1000);
  const toTs = initialToTs
    ? parseInt(initialToTs)
    : GetRounderdOneHourTs(new Date().valueOf());
  const interval = getRoundedInterval(
    parseInt(getMinutesDifference(fromTs, toTs))
  );
  const range =
    (mwQuery.get(GlobalRouteParams.Range) as TimeRangeOption) ||
    TimeRangeOption.OneHour;
  const [dateRange, setDateRange] = useState<DateRange>({
    fromTs,
    toTs,
    interval,
    range,
  });

  useEffect(() => {
    if (isLive && range !== TimeRangeOption.OneHour) {
      handleGoLive();
    }
  }, [isLive, range]);

  useEffect(() => {
    const currentParams = getTimeRangeFromLocation(currentLocation);
    const oldParams = getTimeRangeFromLocation(previousLocation);
    /* Kaushalbhai:- this needs to be change */
    if (currentLocation.pathname.includes("/alert/create-rule")) {
      return;
    }
    if (!currentParams.fromTs || !currentParams.toTs) {
      let fromTs = GetRounderdOneHourTs(new Date().valueOf() - 3600 * 1000);
      let toTs = GetRounderdOneHourTs(new Date().valueOf());
      let range = TimeRangeOption.OneHour;

      PrintLog("fromTs is not set, setting to one hour ago");

      const isOldRouteHasParams = oldParams.fromTs && oldParams.toTs;
      if (isOldRouteHasParams) {
        PrintLog("old route has params, using them");
        fromTs = oldParams.fromTs;
        toTs = oldParams.toTs;
        range = oldParams.range || TimeRangeOption.Custom;
      }

      handleTimeRange(fromTs, toTs, range);
    } else {
      PrintLog(
        "Already has params in url:" +
          currentParams.fromTs +
          "to" +
          currentParams.toTs
      );
      if (
        currentParams.range &&
        currentParams.range !== TimeRangeOption.Custom
      ) {
        const range = getTimeFromRange(currentParams.range);
        if (
          dateRange.fromTs !== range.fromTs ||
          dateRange.toTs !== range.toTs
        ) {
          let diffInMinutes = parseInt(
            getMinutesDifference(dateRange.fromTs, range.fromTs)
          );
          if (diffInMinutes < 0) {
            diffInMinutes = -diffInMinutes;
          }
          let diffInMinutesTo = parseInt(
            getMinutesDifference(dateRange.toTs, range.toTs)
          );
          if (diffInMinutesTo < 0) {
            diffInMinutesTo = -diffInMinutesTo;
          }
          if (
            diffInMinutes > MaxMinutesToChange ||
            diffInMinutesTo > MaxMinutesToChange
          ) {
            setDateRange({
              fromTs: range.fromTs,
              toTs: range.toTs,
              interval: range.interval,
              range: range.range,
            });
            handleTimeRange(range.fromTs, range.toTs, range.range);
          }
        }
      } else if (!currentParams.range) {
        mwQuery.set(GlobalRouteParams.Range, TimeRangeOption.Custom);
        redirect();
      } else if (
        currentParams.fromTs !== dateRange.fromTs ||
        currentParams.toTs !== dateRange.toTs
      ) {
        // This case will happend when user manually change the date range
        // Using our exposed handleTimeRange function
        setDateRange({
          fromTs: currentParams.fromTs,
          toTs: currentParams.toTs,
          interval: currentParams.interval,
          range: currentParams.range,
        });
        handleTimeRange(
          currentParams.fromTs,
          currentParams.toTs,
          currentParams.range
        );
      }
    }
  }, [
    currentLocation.search,
    getTimeRangeFromLocation,
    previousLocation?.search,
    PrintLog,
  ]);

  const handleTimeRange = (
    fromTs: number,
    toTs: number,
    range?: TimeRangeOption
  ) => {
    if (fromTs === 0 || toTs === 0) {
      PrintLog("date range is not valid");
      return;
    } else {
      // check for valid range
      if (fromTs > toTs) {
        PrintLog("date range is not valid");
        return;
      }
    }
    mwQuery.set(GlobalRouteParams.FromTs, fromTs + "");
    mwQuery.set(GlobalRouteParams.ToTs, toTs + "");
    // Condition to check if range is not sent
    // Because other modules calling this function with only fromTs and toTs
    // All modules need to use GlobalDatePicker to set range
    if (range) {
      mwQuery.set(GlobalRouteParams.Range, range);
    } else {
      mwQuery.set(GlobalRouteParams.Range, TimeRangeOption.Custom);
    }
    // mwQuery.set(GlobalRouteParams.Live, "false");
    redirect();
  };

  const handleGoLive = () => {
    mwQuery.set(GlobalRouteParams.Live, "true");
    const range = getTimeFromRange(TimeRangeOption.OneHour);
    mwQuery.set(GlobalRouteParams.FromTs, range.fromTs + "");
    mwQuery.set(GlobalRouteParams.ToTs, range.toTs + "");
    mwQuery.set(GlobalRouteParams.Range, TimeRangeOption.OneHour);
    redirect();
  };
  const handleGoOffline = () => {
    mwQuery.set(GlobalRouteParams.Live, "false");
    redirect();
  };
  const redirect = (excludeKey = "") => {
    const findParamsNotInMwQuery = new URLSearchParams(coreQuery.toString());
    findParamsNotInMwQuery.forEach((value, key) => {
      if (!mwQuery.has(key) && key !== excludeKey) {
        mwQuery.set(key, value);
      } else if (
        mwQuery.has(key) &&
        coreQuery.get(key) &&
        coreQuery.get(key) !== mwQuery.get(key) &&
        !AllRouteParams.includes(key)
      ) {
        mwQuery.set(key, value);
      }
    });
    const pathName = window.location.pathname;
    const hash = window.location.hash;
    const searchString = mwQuery.toString();
    const path = pathName + "?" + searchString + hash;
    PrintLog("redirecting to" + path);
    navigate(path, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state: state || {},
    });
  };

  const getAllParams = () => {
    return getQueryParams(coreQuery);
  };

  const handleApplyFilters = (filtersToApply: QueryParams) => {
    if (Object.keys(filtersToApply).length === 0) {
      mwQuery.delete(GlobalRouteParams.Filters);
      redirect(GlobalRouteParams.Filters);
      return;
    }
    const existingFilters = getAppliedFilters();
    const newFilters: QueryParams = { ...existingFilters, ...filtersToApply };
    Object.keys(newFilters).forEach((key) => {
      if (!newFilters[key] || newFilters[key].values.length === 0) {
        delete newFilters[key];
      }
    });
    if (Object.keys(newFilters).length === 0) {
      mwQuery.delete(GlobalRouteParams.Filters);
      redirect(GlobalRouteParams.Filters);
      return;
    }
    const data = EncodeStringToShortUrl(JSON.stringify(newFilters));
    if (data.length === 0) return;
    mwQuery.set(GlobalRouteParams.Filters, data);
    redirect();
  };
  const getAppliedFilters = (): QueryParams => {
    const filters = mwQuery.get(GlobalRouteParams.Filters);
    if (!filters) {
      return {};
    }
    const dataUrl = DecodeShortUrlToString(filters);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data = JSON.parse(dataUrl);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
      return data;
    } catch (error) {
      console.warn(error);
      return {};
    }
  };
  const handleRefreshClick = () => {
    if (!range) return;
    const selectedRange = range || TimeRangeOption.OneHour;
    if (selectedRange === TimeRangeOption.Custom) return;
    const newRange = getTimeFromRange(selectedRange);
    mwQuery.set(GlobalRouteParams.FromTs, newRange.fromTs + "");
    mwQuery.set(GlobalRouteParams.ToTs, newRange.toTs + "");
    mwQuery.set(
      GlobalRouteParams.Range,
      newRange.range || range || TimeRangeOption.OneHour
    );
    redirect();
  };
  const appliedFilters = getAppliedFilters();
  const data: MwRouteData = {
    params: {
      dateRange,
      debug,
      isLive,
      query: mwQuery,
      allParams: getAllParams(),
      appliedFilters,
      previousLocationPathName: previousLocation?.pathname,
    },
    actions: {
      handleTimeRange,
      handleGoLive,
      handleGoOffline,
      handleApplyFilters,
      handleRefreshClick,
    },
  };
  return data;
};
export default useMwRouteData;
