import LogoLoader from "core/components/v2/loader/logo-loader";
import { AIShineIcon, RunQueryIcon } from "core/components/v2/svg/icons";
import AppContext from "core/components/wrapper/context";
import CreateRequest from "core/xhr";
import React, { useEffect, useState } from "react";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { DateRange, QueryParams } from "views/layouts/app/routes/model";
import {
  AddUpdateWidgetBuilder,
  CustomWidget,
  FilterOp,
  Query,
  RequestParam,
  defaultQuery,
} from "views/modules/builder/entities/builder.entities";
import { QueryResponse } from "../model";
import { convertOperatorToEnum, getFilters } from "../utils";

export interface AISearchProps {
  handleRunQuery?: (
    config?: AddUpdateWidgetBuilder,
    dateRange?: DateRange
  ) => void;
  handleAISearchFocus: (activeStatus: boolean) => void;
  handleAiSearchInput: (text: string) => void;
  handleSearchedQuery: (text: string) => void;
  handleClosePopover: () => void;
  aiSearchInput: string;
}

interface FilterType {
  attribute: string;
  operator: FilterOp;
  value: string;
}

const AISearch = (props: AISearchProps) => {
  const {
    handleRunQuery,
    handleAISearchFocus,
    handleAiSearchInput,
    handleClosePopover,
    handleSearchedQuery,
    aiSearchInput,
  } = props;
  const routeData = React.useContext(MwRouteContext);
  const context = React.useContext(AppContext);
  const sendEvent = useMixPanel();

  const [aiInput, setAiInput] = useState(aiSearchInput);
  const [queryLoader, setQueryLoader] = useState(false);
  const [shouldSendOnClickEvent, setShouldSendOnClickEvent] = useState(true);
  const [queryResp, setQueryResp] = useState<QueryResponse>({
    question_id: "",
    generated_query: {
      metric: "",
      aggregation: "",
      group_by: [] as string[],
      resource_type: "",
      filter_required: false,
      filters: [] as FilterType[],
      params: [{ key: "from_ts", value: 0 }],
    },
  });
  const [isRunQueryIconClicked, setIsRunQueryIconClicked] = useState(false);

  const dateRange: DateRange = {
    fromTs: routeData?.params.dateRange.fromTs || 0,
    toTs: routeData?.params.dateRange.toTs || 0,
  };

  useEffect(() => {
    if (queryResp.question_id != "") {
      handleQueryBuilder();
      handleClosePopover();
    }
  }, [queryResp]);

  useEffect(() => {
    aiSearchInput.trim().length === 0 && setAiInput("");
  }, [aiSearchInput]);

  const handleQueryBuilder = () => {
    if (queryResp) {
      buildeQueryBuilderConfig(queryResp);
    }
  };

  const buildeQueryBuilderConfig = (data: QueryResponse) => {
    const userQuery: Query = { ...defaultQuery };
    const {
      metric,
      group_by,
      resource_type,
      filters,
      aggregation,
      params,
      limit,
      sorting,
    } = data.generated_query;
    const convertedFilter: QueryParams = {};
    for (const f of filters) {
      const { attribute, operator, value } = f;
      convertedFilter[attribute] = {
        values: [value.toString()],
        operator: convertOperatorToEnum(operator),
      };
    }
    userQuery.with = getFilters(convertedFilter);
    let builderColumns: string[] = [];
    let averageexQ = "";
    if (aggregation) {
      if (aggregation === "average") {
        averageexQ = `avg(${metric})`;
      } else {
        averageexQ = `${aggregation}(${metric})`;
      }
      // else if (aggregation === "max") {
      //   averageexQ = `max(${metric},value(avg))`;
      // }
    } else {
      averageexQ = metric;
    }
    builderColumns = [...builderColumns, averageexQ];

    if (group_by && group_by.length > 0) {
      userQuery.with.push({
        key: "SELECT_DATA_BY",
        value: group_by,
        is_arg: true,
      });
    }

    if (averageexQ != "") {
      userQuery.with.push({
        key: "ORDER_BY_METRICS",
        value: {
          [averageexQ]: "desc",
        },
        is_arg: true,
      });
    }

    if (limit) {
      userQuery.with.push(limit);
    }

    userQuery.columns = builderColumns;

    userQuery.source.name = resource_type;

    const resource_temp = {
      columns: builderColumns,
      resourceType: resource_type,
      name: `text-to-query`,
      widgetAppId: 5,
    };

    const _params: RequestParam[] = [];
    if (params) {
      for (const p of params) {
        _params.push({ key: p.key, value: p.value });
      }
    } else {
      _params.push({ key: "from_ts", value: dateRange.fromTs });
      _params.push({ key: "to_ts", value: dateRange.toTs } as RequestParam);
    }

    if (_params) {
      for (const p of _params) {
        if (p.key === "from_ts") {
          dateRange.fromTs = p.value;
        }
        if (p.key === "to_ts") {
          dateRange.toTs = p.value;
        }
      }
    }
    // to prevent the timerange order change coming from llm response
    if (dateRange.fromTs > dateRange.toTs) {
      [dateRange.fromTs, dateRange.toTs] = [dateRange.toTs, dateRange.fromTs];
      if (_params) {
        for (const p of _params) {
          if (p.key === "from_ts") {
            p.value = dateRange.fromTs;
          }
          if (p.key === "to_ts") {
            p.value = dateRange.toTs;
          }
        }
      }
    }
    if (routeData.params.debug) {
      _params.push({ key: "debug", value: "true" });
    }
    const widgetRequest: CustomWidget = {
      label: "",
      builderId: 0,
      scopeId: 0,
      widgetAppId: 5,
      builderConfig: userQuery,
      builderViewOptions: {
        resource: {
          ...resource_temp,
          with: userQuery.with,
        },
      },
      params: _params,
    };

    const d: AddUpdateWidgetBuilder = {
      action: "update",
      body: widgetRequest,
      inflight: true,
    };
    if (handleRunQuery) handleRunQuery(d, dateRange);
  };

  const processResponse = (response: {
    status: number;
    data: QueryResponse;
  }) => {
    if (response && response.status === 200) {
      const data = response.data || {
        metric: "",
        aggregation: "",
        group_by: [],
        resource_type: "",
        filter_required: false,
        filter: [],
      };

      if (data) {
        setQueryResp(data);
      }
    }
  };

  const handleGenerateClick = (text: string) => {
    // Todo: need to replce below mentioned setState with API Call
    setQueryLoader(true);
    const data = JSON.stringify({
      user_id: (context.user?.account?.uid || 0).toString(),
      message: text,
    });
    CreateRequest({
      url: "/builder/widget/text-to-query",
      method: "POST",
      data: data,
    })
      .then((res) => {
        if (res.status === 500) {
          setQueryLoader(false);
          return;
        }
        if (!res.data.status && res.data.error) {
          setQueryLoader(false);
          return;
        }
        processResponse(res);
        setQueryLoader(false);
        // setResources(res.data);
        handleClosePopover();
      })
      .catch((e) => {
        console.log("e---", e);
        setQueryLoader(false);
      });
    // setQLText("SELECT * FROM Span WHERE http.method = 'GET' ");
    // setDisableGenerate(true);
  };

  return (
    <div className="ai-search-container-outer-div">
      <div className="search-container">
        <div className="beta-tag">Beta</div>
        <div className={`search-icon`}>
          <AIShineIcon />
        </div>
        <div className="input-field-container">
          <input
            id={`ai-input`}
            className="input-field"
            placeholder="Type your thoughts, then hit play or press enter to see the magic happen"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAiInput(e.target.value);
              handleAiSearchInput(e.target.value);
            }}
            value={aiInput}
            onClick={() => {
              if (shouldSendOnClickEvent) {
                sendEvent("ai_search_play");
                setShouldSendOnClickEvent(false);
              }
            }}
            onFocus={() => handleAISearchFocus(true)}
            onBlur={() => {
              if (!isRunQueryIconClicked) {
                handleAISearchFocus(false);
              }
              setIsRunQueryIconClicked(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && aiInput.length) {
                handleGenerateClick(aiInput);
                handleSearchedQuery(aiInput);
              }
            }}
            disabled={queryLoader}
          />
        </div>
        <div
          className={`query-icon ${queryLoader ? "run-query-disabled" : ""}`}
          onMouseDown={() => setIsRunQueryIconClicked(true)}
          onClick={() => {
            if (!queryLoader) {
              sendEvent("ai_search_play");
              handleGenerateClick(aiInput);
              handleSearchedQuery(aiInput);
              setShouldSendOnClickEvent(true);
            }
          }}
        >
          {queryLoader ? <LogoLoader size={32} /> : <RunQueryIcon size={16} />}
        </div>
      </div>
    </div>
  );
};

export default AISearch;
