import { APIStatus } from "core/application/utils";
import DescriptiveDropdown from "core/components/v2/dropdown-selection/dropdown-with-description";
import {
  DescriptiveDropdownOptions,
  DropdownMode,
} from "core/components/v2/dropdown-selection/entity";
import { debounceHandler } from "core/utils";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recievedBuilderMetricList } from "store/widgets/actions";
import { fetchMetricList } from "store/widgets/api";
import { DataType, MetricsAPIResp } from "store/widgets/entities";
import { getBuilderMetrics } from "store/widgets/selectors";
import {
  GroupByItem,
  KpiType,
  SELECT_DATA_BY,
  SelectGroupBy,
  getWidgetType,
  toKpiType,
} from "views/modules/builder/entities/builder.entities";
import { MetricMetadata } from "views/modules/settings-v2/metrics-control-panel/entities";
import { getMetricDetails } from "../../common/utils";
import { BuilderSelectionProps } from "../../views/components/entities";
import MetricMetadataView from "../metric-metadata";
import MetricMetadataFooterView from "../metric-metadata/footer";

const GroupBy = ({
  query,
  chartType,
  onConfigUpdate,
}: BuilderSelectionProps) => {
  const dispatch = useDispatch();
  const builderMetrics = useSelector(getBuilderMetrics);

  const currentMetric =
    query.columns.length > 0 ? (query.columns[0] as string) : "";
  const currentMetricDetails = useMemo(
    () => getMetricDetails(currentMetric),
    [currentMetric]
  );

  const kpiType = useMemo(
    () => toKpiType(query.source.name),
    [query.source.name]
  );

  const options = useMemo(() => {
    if (kpiType === KpiType.KpiTypeNone) return [];

    return ((builderMetrics.groupby?.items as GroupByItem[]) || []).map(
      (item): DescriptiveDropdownOptions<MetricMetadata> => {
        if (!item.metadata) {
          item.metadata = {
            attributes: [],
            description: "",
            originalKey: item.name,
            resourceType: item.resource || "", // item.resource will never be empty with metrics-v2 API
            resourceAttributes: [],
            sum: {
              aggregation_temporality: "",
              monotonic: false,
              value_type: "",
            },
            unit: "",
          };
        }
        item.metadata.metricKey = item.label || item.name;

        return {
          label: item.label || item.name,
          value: item.name,
          descriptionDetails: item.metadata,
        };
      }
    );
  }, [chartType, kpiType, query.columns, builderMetrics.groupby?.items]);

  const currentGroupByIdx = useMemo(
    () => query.with?.findIndex((w) => w.key === SELECT_DATA_BY),
    [query?.with?.length]
  );
  const currentGroupBy =
    currentGroupByIdx !== undefined && currentGroupByIdx > -1
      ? query.with?.[currentGroupByIdx]
      : undefined;

  const isInvalidSelection = useMemo(() => {
    if (!currentGroupBy?.value || !Array.isArray(currentGroupBy.value))
      return false;

    return currentGroupBy.value.some((val) => {
      if (val === SelectGroupBy) {
        return true;
      }
    });
  }, [currentGroupBy?.value]);

  const handleChange = (value: string | string[]) => {
    const values = value as string[];

    const newQuery = { ...query };
    if (newQuery.with) {
      if (
        currentGroupByIdx !== undefined &&
        currentGroupByIdx > -1 &&
        Array.isArray(newQuery.with[currentGroupByIdx].value)
      ) {
        if (values.length > 0) {
          newQuery.with[currentGroupByIdx].value = values;
        } else {
          newQuery.with.splice(currentGroupByIdx, 1);
        }
      } else {
        newQuery.with.push({
          key: SELECT_DATA_BY,
          value: values,
          is_arg: true,
        });
      }
    } else if (values.length > 0) {
      newQuery.with = [
        {
          key: SELECT_DATA_BY,
          value: values,
          is_arg: true,
        },
      ];
    }

    onConfigUpdate(newQuery);
  };

  const requestGroupByOptions = (searchVal: string) => {
    // Don't make api call if metric is not selected
    if (!query.source.name) return;

    dispatch(
      fetchMetricList(
        {
          kpiType: kpiType,
          widgetType: getWidgetType(chartType),
          dataType: DataType.GroupBy,
          search: searchVal,
          resource: query.source.name,
          metric: currentMetricDetails?.metricName,
        },
        (resp: MetricsAPIResp) =>
          recievedBuilderMetricList(DataType.GroupBy, resp)
      )
    );
  };

  return (
    <DescriptiveDropdown
      className={currentGroupBy?.value ? "data" : ""}
      isLoading={builderMetrics.groupby?.apiStatus === APIStatus.LOADING}
      isError={isInvalidSelection}
      mode={DropdownMode.AddOptions}
      placeholder={"Select Group By"}
      notFoundContent="No tags found"
      extraNoteTitle={options.length >= 100 ? "Search for more..." : undefined}
      options={options}
      defaultValue={(currentGroupBy?.value as string[]) || []}
      onChange={handleChange}
      onFocus={() => requestGroupByOptions("")}
      onSearch={debounceHandler(-2, requestGroupByOptions, 500)}
      descriptionJSX={(metadata?: MetricMetadata) => (
        <MetricMetadataView metadata={metadata} />
      )}
      footerJSX={() => <MetricMetadataFooterView />}
    />
  );
};

export default GroupBy;
