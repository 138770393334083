import React, { ReactNode, useState } from "react";
import Dialog from "core/components/v2/dialog";
import Button from "core/components/v2/button";
import "./_style.scss";
import { DeleteDialogIcon } from "./icons";
import FormTextInput from "core/components/v2/form/form-text-input";
import Tooltip from "core/components/v2/tooltip";

export enum ConfirmationType {
  Delete = "delete",
  Update = "update",
}

interface ConfirmationDialogProps {
  type?: ConfirmationType;
  title?: string;
  description?: string | JSX.Element;
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  handleConfirmEvent?: () => void;
  handleCancelEvent?: () => void;
  headerIcon?: JSX.Element;
  entityName?: string;
  entityType?: string;
  enableDoubleConfirmation?: boolean;
  isLoading?: boolean;
  inputPlaceholder?: string;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    type = ConfirmationType.Delete,
    isOpen = true,
    onClose = () => {},
    title,
    description,
    children,
    confirmBtnText,
    cancelBtnText,
    handleCancelEvent,
    handleConfirmEvent,
    headerIcon,
    entityName,
    entityType,
    enableDoubleConfirmation = false,
    isLoading = false,
    inputPlaceholder,
  } = props;

  const [firstDeleteclicked, setFirstDeleteClicked] = useState(false);
  const [deleteFormInput, setDeleteFormInput] = useState("");

  return (
    <Dialog
      contentClassName="confirmation-dialog"
      isOpen={isOpen}
      position="center"
      onClose={() => {
        onClose();
        setDeleteFormInput("");
        setFirstDeleteClicked(false);
      }}
      verticalCenter={true}
      size="sx"
    >
      <div className="dlt-icon-space">{headerIcon ?? <DeleteDialogIcon />}</div>
      <div className="title-and-description-container">
        <div className="title">
          {firstDeleteclicked
            ? `Sure about deleting ${entityName ?? ""} ${entityType?.toLowerCase() ?? ""}?`
            : title}
        </div>
        <div className="description">
          {firstDeleteclicked
            ? `Type in the text below to confirm deleting the ${entityType?.toLowerCase() ?? ""}`
            : description}
        </div>
      </div>
      {children}
      {firstDeleteclicked && enableDoubleConfirmation && (
        <div className="form-input-group">
          <FormTextInput
            id={`delete-${entityType?.toLowerCase()}-name`}
            value={deleteFormInput}
            placeholder={inputPlaceholder ?? `Enter ${entityType} Name`}
            onChange={(value) => {
              setDeleteFormInput(value);
            }}
          />
        </div>
      )}
      <div className="dialog-btn-container">
        {!firstDeleteclicked && (
          <div className="button-container">
            <Button
              primary={type === ConfirmationType.Delete}
              secondary={type === ConfirmationType.Update}
              onClick={() => {
                handleCancelEvent?.();
                setDeleteFormInput("");
              }}
            >
              {cancelBtnText ?? "Cancel"}
            </Button>
          </div>
        )}
        <Tooltip
          content={
            enableDoubleConfirmation &&
            firstDeleteclicked &&
            deleteFormInput !== entityName
              ? `${entityType} name did not match, please enter correct ${entityType?.toLowerCase()} name`
              : ""
          }
        >
          <div className="button-container">
            <Button
              error={type === ConfirmationType.Delete}
              secondary={
                type === ConfirmationType.Delete && !firstDeleteclicked
              }
              primary={type === ConfirmationType.Update}
              disabled={firstDeleteclicked && deleteFormInput !== entityName}
              onClick={() => {
                setDeleteFormInput("");
                if (!enableDoubleConfirmation) {
                  handleConfirmEvent?.();
                } else {
                  firstDeleteclicked
                    ? handleConfirmEvent?.()
                    : setFirstDeleteClicked(!firstDeleteclicked);
                }
              }}
              loading={isLoading}
            >
              {!firstDeleteclicked
                ? confirmBtnText ?? "Confirm"
                : `Delete ${entityType ?? ""}`}
            </Button>
          </div>
        </Tooltip>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
