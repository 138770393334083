import React, { useEffect, useState } from "react";
import AISearch, { AISearchProps } from "./AISearch";
import QueryOptionsList, { QueryOptionsListProps } from "./QueryOptionsList";

type PopoverContentProps = Omit<AISearchProps, "handleAISearchFocus"> &
  QueryOptionsListProps & { editQuery: boolean };

const PopoverContent = (props: PopoverContentProps) => {
  const {
    handleRunQuery,
    handleAiSearchInput,
    handleClosePopover,
    handleSearchedQuery,
    editQuery,
    currentType,
    options,
    selectOptionHandler,
    runningQuery,
    highlitedIndex,
    handleApplyFilter,
    inFilter,
    aiSearchInput,
  } = props;
  const [width, setWidth] = useState(0);
  const [isAISearchActive, setIsAISearchActive] = useState(false);

  const handleAISearchFocus = (activeStatus: boolean) => {
    setIsAISearchActive(activeStatus);
  };

  useEffect(() => {
    const allQuery: NodeListOf<HTMLElement> =
      document.querySelectorAll(".global-search");
    if (allQuery.length > 0) {
      const check = allQuery[allQuery.length - 1].getBoundingClientRect();
      setWidth(check.width);
    }
  }, []);

  useEffect(() => {
    editQuery && setIsAISearchActive(true);
  }, [editQuery]);

  return (
    <div className="options-wrapper" style={{ width: `${width}px` }}>
      {handleRunQuery && (
        <AISearch
          handleRunQuery={handleRunQuery}
          handleAISearchFocus={handleAISearchFocus}
          handleAiSearchInput={handleAiSearchInput}
          handleClosePopover={handleClosePopover}
          handleSearchedQuery={handleSearchedQuery}
          aiSearchInput={aiSearchInput}
        />
      )}
      {!isAISearchActive && (
        <QueryOptionsList
          currentType={currentType}
          options={options}
          selectOptionHandler={selectOptionHandler}
          runningQuery={runningQuery}
          highlitedIndex={highlitedIndex}
          handleApplyFilter={handleApplyFilter}
          inFilter={inFilter}
        />
      )}
    </div>
  );
};

export default PopoverContent;
